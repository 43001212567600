import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout.js";
import Content from "../components/content";
import articlesStyles from "../components/post.module.scss";
import { Link } from "gatsby";

export const query = graphql `
    query($name: String) {
        allContentfulBlogPosts (filter: {categories: {elemMatch: {name: {eq: $name}}}}) {
            edges{
                node {
                    title
                    slug
                    date(formatString:"MMMM DD, YYYY")
                    categories {
                        name
                    }
                    headerImage {
                        file {
                            url
                            fileName
                        }
                    }
                }
            }
        }
    }
    `

const CategoryFilter = (props) => {

    return (
        <Layout>
            <Content>
            <div className={articlesStyles.container}>
            <div>
                <ol className={articlesStyles.cards}>
                    { props.data.allContentfulBlogPosts.edges.map((edge) => {
                        return (
                            <li className={articlesStyles.cardItem}>
                                <div className={articlesStyles.card}>
                                    <Link className={articlesStyles.link} to={`/blog/${edge.node.slug}`}>
                                        <div className={articlesStyles.postImage}>
                                            <img className={articlesStyles.image} src={edge.node.headerImage.file.url} alt={edge.node.headerImage.file.fileName} />
                                        </div>
                                        <div className={articlesStyles.postHeader}>
                                            <p className={articlesStyles.category}>{edge.node.categories[0].name}</p>
                                            <h2>{edge.node.title}</h2>
                                            <p>{edge.node.date}</p>
                                        </div>
                                    </Link>
                                </div>
                            </li>
                        )
                    })}
                </ol>
            </div>
        </div>
            </Content>
        </Layout>
    )
}

export default CategoryFilter;